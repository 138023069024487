<template>
    <!--
    Компонент кнопка отмены
    -->

    <dom-button
        class="dom-button-decline"
        :left-icon="leftIcon"
        :right-icon="rightIcon"
        :label="label"
        :disabled="disabled"
        @click="$emit('click', $event)"
    />

</template>

<script>
import DomButton from "@/views/elements/DomButton";

export default {
    components: {DomButton},
    props: {
        label: {},
        disabled: {},
        leftIcon: {
            default: "el-icon-circle-close"
        },
        rightIcon: {
            default: "el-icon-caret-right"
        },
    }
}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.dom-button-decline {
    height: 50px;
    background: $dom-white;
    border: 2px solid $dom-light-blue;

    ::v-deep span {
        color: $dom-light-blue;
        font-weight: 600;
    }

    ::v-deep i {
        color: $dom-light-blue;
    }
}


</style>
