<template>
    <!--
    Компонент выбора даты
    -->
    <el-date-picker
        class="dom-datepicker"
        v-model="valueInternal"
        type="date"
        :firstDayOfWeek="1"
        format="dd.MM.yyyy"
        v-mask="'##.##.####'"
        :editable="true"
        :disabled="disabled"
        :default-value="defaultValue"
        :readonly="readonly"
        :picker-options="pickerOptions"
        :prefix-icon="prefixIcon"
        :placeholder="placeholder">
    </el-date-picker>

</template>

<script>
import ValueInternal from "@/views/mixins/ValueInternal";

import Inputmask from 'inputmask'
import Vue from 'vue'

Vue.directive('mask', {
    bind: function (el, binding) {
        Inputmask(binding.value).mask(el.getElementsByTagName('INPUT')[0])
    }
})

export default {
    name: "dom-datepicker",

    props: {
        placeholder: {},
        prefixIcon: {},
        disabled : {
            type: Boolean,
            default: false,
        },
        defaultValue: {},
        readonly: {
            type: Boolean,
            default: false,
        },
        pickerOptions: {
            type: Object,
            default: () => ({}),
        },
    },

    mixins: [ValueInternal],


}
</script>

<style scoped lang="scss">

@import "~@/theme.scss";

.dom-datepicker {
    width: 100%;

    ::v-deep .el-date-editor {
        width: 100%;
    }


    ::v-deep .el-input__prefix {
        color: $dom-light-blue;
        font-size: 20px;
        width: 50px;
        padding-top: 1px;
    }

    ::v-deep .el-input__suffix {
        color: $dom-light-blue;
        font-size: 20px;
        padding-top: 2px;
    }

    ::v-deep .el-input__inner {
        padding: 25px 60px;
        font-size: 18px;
        color: $dom-black;
    }

}

</style>
